import { jsx as _jsx } from "react/jsx-runtime";
/**
 *
 * AuthContext
 *
 */
import React, { useCallback, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getEmailOtp, getPhoneFirebaseOtp, initialize, verifyFirebaseOtp, verifyEmailOtp, initializeSocialLogin, } from '@containers/Auth/slice';
import { selectAuth, selectCandidateDetails, selectCurrentUser, selectQueryParams, selectUser, } from '@containers/Auth/selectors';
import { useFeatureValue, useGrowthBook } from '@growthbook/growthbook-react';
import { growthBookFeatureFlags } from '@utils/featureFlags';
import Mixpanel from 'mixpanel-browser';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { sentrySetUser } from '@utils/sentry';
import { candidateAuthenticated } from '@utils/mixpanel/mixpanelActions';
export const authContextInitialState = {
    isAuthenticated: false,
    isAuthenticating: true,
    triggerEmailOtp: (_userName, _callback) => { },
    triggerPhoneFirebaseOtp: (_phoneNumber, _callback) => { },
    initiateSocialLogin: (_name) => { },
    triggerVerifyEmailOtp: (_code, _callback) => { },
    triggerFirebaseVerifyOtp: (_code, _callback) => { },
    phone: '',
    username: '',
    verificationCodeRequested: false,
    isInitialized: false,
    // currentUser: undefined,
    // candidateDetails: undefined,
    tokens: { accessToken: '' },
};
const AuthContext = React.createContext(authContextInitialState);
function AuthProvider(props) {
    const dispatch = useAppDispatch();
    const state = useAppSelector(selectAuth);
    const user = useAppSelector(selectUser);
    const queryParams = useAppSelector(selectQueryParams);
    const currentUser = useAppSelector(selectCurrentUser);
    const candidateDetails = useAppSelector(selectCandidateDetails);
    const growthBook = useGrowthBook();
    const enableMixPanel = useFeatureValue(growthBookFeatureFlags.MIX_PANEL_ENABLED, false);
    const [search] = useSearchParams();
    const navigate = useNavigate();
    const tokens = useMemo(() => {
        const { accessToken, customToken, idToken, hash_token } = state;
        return { accessToken, customToken, idToken, hash_token };
    }, [state]);
    const triggerEmailOtp = useCallback((username, callback) => {
        dispatch(getEmailOtp({ username, callback }));
    }, [dispatch]);
    const triggerPhoneFirebaseOtp = useCallback((phone, callback) => {
        dispatch(getPhoneFirebaseOtp({ phone, callback }));
    }, [dispatch]);
    const triggerVerifyEmailOtp = useCallback((code, callback) => {
        dispatch(verifyEmailOtp({
            username: (user === null || user === void 0 ? void 0 : user.phone) || (user === null || user === void 0 ? void 0 : user.username),
            code,
            callback,
        }));
    }, [user, dispatch]);
    const triggerFirebaseVerifyOtp = useCallback((code, callback) => {
        dispatch(verifyFirebaseOtp({
            code,
            callback,
        }));
    }, [dispatch]);
    const initiateSocialLogin = (name, callback) => {
        dispatch(initializeSocialLogin({
            socialLoginProviderName: name,
            callback: callback,
        }));
    };
    const redirectPostAuthentication = () => {
        if (queryParams === null || queryParams === void 0 ? void 0 : queryParams.from) {
            const path = `${queryParams === null || queryParams === void 0 ? void 0 : queryParams.from}`;
            navigate(path, { replace: true });
        }
    };
    const value = useMemo(() => ({
        user,
        queryParams,
        isAuthenticated: state.authenticated,
        isAuthenticating: state.authenticating,
        isInitialized: state.initialized,
        triggerEmailOtp,
        triggerPhoneFirebaseOtp,
        initiateSocialLogin,
        triggerVerifyEmailOtp,
        triggerFirebaseVerifyOtp,
        username: user === null || user === void 0 ? void 0 : user.username,
        phone: user === null || user === void 0 ? void 0 : user.phone,
        verificationCodeRequested: state === null || state === void 0 ? void 0 : state.verificationCodeRequested,
        currentUser: currentUser || undefined,
        tokens,
        candidateDetails: candidateDetails || undefined,
        redirectPostAuthentication,
    }), [
        user,
        queryParams,
        state.authenticated,
        state.authenticating,
        state.initialized,
        state === null || state === void 0 ? void 0 : state.verificationCodeRequested,
        triggerEmailOtp,
        triggerPhoneFirebaseOtp,
        triggerVerifyEmailOtp,
        triggerFirebaseVerifyOtp,
        currentUser,
        tokens,
        candidateDetails,
    ]);
    useEffect(() => {
        dispatch(initialize());
    }, []);
    useEffect(() => {
        const tid = search.get('tid') || (user === null || user === void 0 ? void 0 : user.tenant_id);
        growthBook === null || growthBook === void 0 ? void 0 : growthBook.setAttributes(Object.assign(Object.assign({}, growthBook.getAttributes()), { tenant_id: tid ? Number(tid) : null, user_id: (user === null || user === void 0 ? void 0 : user.user_id) ? Number(user === null || user === void 0 ? void 0 : user.user_id) : null }));
        sentrySetUser(user);
        if (value.isAuthenticated) {
            candidateAuthenticated();
        }
    }, [value.isAuthenticated, value.isAuthenticating]);
    useEffect(() => {
        const enabled = enableMixPanel && process.env.NODE_ENV === 'production';
        Mixpanel.init(process.env.MIXPANEL_TOKEN, {
            ignore_dnt: true,
            debug: false,
            opt_out_tracking_by_default: !enabled,
            track_pageview: false,
            loaded(mixpanel) {
                growthBook.setAttributes(Object.assign(Object.assign({}, growthBook.getAttributes()), { id: mixpanel.get_distinct_id() }));
            },
        });
        if (enabled && Mixpanel && typeof Mixpanel.opt_in_tracking === 'function') {
            Mixpanel === null || Mixpanel === void 0 ? void 0 : Mixpanel.opt_in_tracking();
        }
        else if (typeof Mixpanel.opt_out_tracking === 'function') {
            Mixpanel === null || Mixpanel === void 0 ? void 0 : Mixpanel.opt_out_tracking();
        }
    }, [enableMixPanel]);
    return _jsx(AuthContext.Provider, { value: value, children: props.children });
}
export const AuthConsumer = AuthContext.Consumer;
export { AuthProvider };
export default AuthContext;
