import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, useEffect } from 'react';
import AuthContext from '@contexts/AuthContext';
import { useNavigate } from 'react-router';
import { candidateLoginOtpFilledLoginSuccess } from '@utils/mixpanel/mixpanelActions';
import * as Sentry from '@sentry/react';
export const Redirect = () => {
    const { queryParams } = useContext(AuthContext);
    const navigate = useNavigate();
    useEffect(() => {
        candidateLoginOtpFilledLoginSuccess({});
        if (queryParams === null || queryParams === void 0 ? void 0 : queryParams.from) {
            const path = `${queryParams === null || queryParams === void 0 ? void 0 : queryParams.from}`;
            navigate(path, { replace: true });
        }
        else {
            Sentry.captureMessage(`Redirect page:  ${JSON.stringify(queryParams)}`);
        }
    }, [queryParams === null || queryParams === void 0 ? void 0 : queryParams.from]);
    console.log('Redirecting page content: ', queryParams);
    return (
    // eslint-disable-next-line formatjs/no-literal-string-in-jsx
    _jsx("div", { children: "Redirecting..." }));
};
