import { gql } from '@apollo/client';
export const CHANGE_INTERVIEWER = gql `
  mutation changeInterviewer(
    $from: timestamptz!
    $to: timestamptz!
    $meeting_id: Int!
    $participants: [sch_reschedule_participants_object]!
  ) {
    sch_reschedule_meeting(data: { from: $from, to: $to, meeting_id: $meeting_id, participants: $participants }) {
      data {
        to
      }
    }
  }
`;
