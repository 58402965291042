import { createSelector } from '@reduxjs/toolkit';
import { asyncSlice } from './slice';
import { getSupportedMimeType } from '@contexts/MediaContext/helpers';
export const selectAsyncState = createSelector((state) => state, (state) => state[asyncSlice.name]);
export const selectIsUploadingCompleted = createSelector(selectAsyncState, (state) => state.uploadingCompleted);
export const selectQuestionAttempted = createSelector(selectAsyncState, (state) => state.questionAttempted);
// export const selectIsSubmitted = createSelector(selectAsyncState, (state) => state.isSubmitted);
export const selectIsRecording = createSelector(selectAsyncState, (state) => state.isRecording);
export const selectSavedChunks = createSelector(selectAsyncState, (state) => state.savedChunkRecords);
export const selectRecordedChunks = createSelector(selectAsyncState, (state) => state.recordedChunks);
export const selectVideoPlaybackUrl = createSelector([selectRecordedChunks, selectIsRecording], (chunks, isRecording) => {
    if (!isRecording && chunks.length > 0) {
        const files = chunks.map((record) => record.data);
        const combinedBlob = new Blob(files, { type: getSupportedMimeType() });
        return URL.createObjectURL(combinedBlob);
    }
    else {
        return '';
    }
});
export const selectFailedToSaveChunks = createSelector(selectAsyncState, (state) => state.failedToUploadChunks);
// export const selectSequence = createSelector(selectAsyncState, (state) => state.sequence);
export const selectSequence = createSelector(selectAsyncState, (state) => state.sequence);
export const selectIsFirstFileChunkFailedToUpload = createSelector(selectAsyncState, (state) => state.failedToUploadChunks.some((record) => record.sequence === 1));
export const selectUploadPercentage = createSelector(selectAsyncState, (state) => state.uploadPercentage);
export const selectIsUploading = createSelector(selectAsyncState, (state) => state.isUploading);
