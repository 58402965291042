var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { fork, all, select, call, takeEvery, put, retry } from '@redux-saga/core/effects';
import { storeFailedToSavedChunk, storeSavedChunk, uploadChunk, uploadComplete, saveAnswer, } from '@containers/Assessment/AsyncPlayGround/slice';
import { selectUser } from '@containers/Auth/selectors';
import postData from '@utils/postData';
import { UPLOAD_FILE } from '@containers/App/queries';
import { catchError } from '@utils/sentry';
import { selectFailedToSaveChunks, selectIsRecording, 
// selectIsSubmitted,
// selectIsUploadingCompleted,
selectRecordedChunks, selectSavedChunks, } from '@containers/Assessment/AsyncPlayGround/selectors';
import { axiosPostData } from '@utils/axiosApi';
import { difference, last, sortBy } from 'lodash';
import { selectCurrentQuestion, selectStartSectionResponse } from '@containers/Assessment/selectors';
import { SAVE_ANSWERS } from '@containers/Assessment/queries';
import { getCurrentDate } from '@utils/dateHelpers';
import { fetchNextQuestionById, saveAppState, storeAnswer } from '@containers/Assessment/slice';
import { isIOS, isSafari } from '@utils/helpers';
import { nextAsyncQuestionClick } from '@utils/mixpanel/mixpanelActions';
export function* uploadChunkWorker() {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const chunks = (yield select(selectRecordedChunks));
    const chunk = (yield call(last, chunks));
    const user = (yield select(selectUser));
    const extension = chunk.mimeType && chunk.mimeType.split(';')[0].split('/')[1];
    const formattedChunkPayload = {
        file_type_id: isSafari() || isIOS() ? 10 : 11,
        file: chunk.data,
        timestamp: chunk.timestamp,
        fileSize: chunk.data.size,
        extension: extension,
        original_name: 'video_' + chunk.sequence + '.' + extension,
        owner_id: user === null || user === void 0 ? void 0 : user.user_id,
    };
    try {
        const response = (yield retry(3, 20, postData, {
            queryString: UPLOAD_FILE,
            payload: formattedChunkPayload,
            spreadPayload: true,
        }));
        const { data: _file } = chunk, rest = __rest(chunk, ["data"]);
        if (response && ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.file_upload_file)) {
            const fileUploadData = (_c = (_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.file_upload_file) === null || _c === void 0 ? void 0 : _c.data;
            const URL = (_f = (_e = (_d = response === null || response === void 0 ? void 0 : response.data) === null || _d === void 0 ? void 0 : _d.file_upload_file) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.url;
            const { url: _url } = fileUploadData, newFileUploadData = __rest(fileUploadData, ["url"]);
            const formData = new FormData();
            Object.keys(newFileUploadData).forEach((key) => {
                formData.append(key, newFileUploadData[key]);
            });
            formData.append('file', formattedChunkPayload === null || formattedChunkPayload === void 0 ? void 0 : formattedChunkPayload.file);
            yield call(axiosPostData, URL, formData);
            yield put(storeSavedChunk({ chunk: Object.assign(Object.assign({}, rest), { file_id: (_h = (_g = response.data) === null || _g === void 0 ? void 0 : _g.file_upload_file) === null || _h === void 0 ? void 0 : _h.id }) }));
        }
        else {
            throw Error('No Response from file upload api');
        }
    }
    catch (error) {
        const { file: _file } = formattedChunkPayload, rest = __rest(formattedChunkPayload, ["file"]);
        yield put(storeFailedToSavedChunk({ chunk: chunk }));
        yield call(catchError, {
            title: 'Async file update failed',
            extraScope: { key: 'chunk', value: JSON.stringify(rest) },
            error: error,
            skipToast: true,
        });
    }
}
export function* uploadChunkObserverWorker() {
    try {
        const isRecording = (yield select(selectIsRecording));
        if (!isRecording) {
            const savedChunkRecords = (yield select(selectSavedChunks));
            const recordedChucks = (yield select(selectRecordedChunks));
            const failedToSaveChunks = (yield select(selectFailedToSaveChunks));
            const allRecordedSequence = sortBy(recordedChucks, 'sequence').map((record) => record.sequence);
            const allSavedSequence = sortBy(savedChunkRecords, 'sequence').map((record) => record.sequence);
            const diff = difference(allRecordedSequence, allSavedSequence);
            if (diff.length === 0) {
                yield put(uploadComplete({ uploadPercentage: 100 }));
            }
            else if (diff.length && savedChunkRecords.length + failedToSaveChunks.length === recordedChucks.length) {
                yield put(uploadComplete({ uploadPercentage: Math.ceil((savedChunkRecords.length / recordedChucks.length) * 100) }));
            }
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'Async post chunk upload failed',
            error: error,
            skipToast: true,
        });
    }
}
export function* saveAnswerWorker({ payload, }) {
    var _a, _b, _c;
    try {
        // const isUploadingCompleted = (yield select(selectIsUploadingCompleted)) as boolean;
        // const isSubmitted = (yield select(selectIsSubmitted)) as boolean;
        // if (isUploadingCompleted && isSubmitted) {
        const currentQuestion = (yield select(selectCurrentQuestion));
        const startSectionResponse = (yield select(selectStartSectionResponse));
        const savedChunks = (yield select(selectSavedChunks));
        const formattedChunks = savedChunks.map((chunk) => {
            var _a, _b;
            return ({
                question_id: currentQuestion.id,
                section_id: startSectionResponse.id,
                answered_at: (_b = (_a = getCurrentDate()) === null || _a === void 0 ? void 0 : _a.toUTC()) === null || _b === void 0 ? void 0 : _b.toISO(),
                file_id: chunk.file_id,
                sequence: chunk.sequence,
            });
        });
        const response = (yield retry(3, 20, postData, {
            queryString: SAVE_ANSWERS,
            payload: formattedChunks,
        }));
        // const response = (yield call(postData, {
        //   queryString: SAVE_ANSWERS,
        //   payload: formattedChunks,
        // })) as SaveAnswersOutput;
        const result = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.ae_save_answer[0];
        if (result === null || result === void 0 ? void 0 : result.success) {
            nextAsyncQuestionClick();
            yield put(storeAnswer(result.data));
            yield put(saveAppState({ data: { remaining_time: 0 } }));
            yield put(fetchNextQuestionById({ callback: payload.callback }));
        }
        else {
            const err = Array.isArray(result === null || result === void 0 ? void 0 : result.error_message) ? (_b = result === null || result === void 0 ? void 0 : result.error_message) === null || _b === void 0 ? void 0 : _b[0] : result === null || result === void 0 ? void 0 : result.error_message;
            throw Error(`Failed to save answer:  ${err}`);
        }
        // }
    }
    catch (error) {
        yield call(catchError, {
            title: 'saveAnswerWorker',
            error: error,
        });
        if ((_c = payload === null || payload === void 0 ? void 0 : payload.callback) === null || _c === void 0 ? void 0 : _c.onError) {
            yield call(payload.callback.onError, error);
        }
    }
}
export function* saveFileRecordWatcher() {
    yield takeEvery([storeSavedChunk.type, storeFailedToSavedChunk.type], uploadChunkObserverWorker);
}
export function* uploadChunkWatcher() {
    yield takeEvery(uploadChunk.type, uploadChunkWorker);
}
export function* saveAnswerWatcher() {
    yield takeEvery(saveAnswer.type, saveAnswerWorker);
}
export function* asyncAnswerRootSaga() {
    yield all([fork(uploadChunkWatcher), fork(saveFileRecordWatcher), fork(saveAnswerWatcher)]);
}
