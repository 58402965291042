import { call, takeLatest, fork, put } from 'redux-saga/effects';
import fetchData from '@utils/fetchData';
import { catchError } from '@utils/sentry';
import { CONFIRM_DRIVE_MEETING_SLOT, GET_GUEST_OCCURRENCE_ID_QUERY } from './queries';
import { confirmDriveSlotRequest, confirmDriveSlotSuccess, driveDetailsSuccess, fetchDriveDetails } from './slice';
import { first } from 'lodash';
import postData from '@utils/postData';
export function* fetchDriveOccurrenceGuestWorker(action) {
    const { driveOccurrenceGuestId, callback } = action.payload;
    try {
        const driveDetailResponse = (yield call(fetchData, {
            queryString: GET_GUEST_OCCURRENCE_ID_QUERY,
            queryVariables: { id: driveOccurrenceGuestId },
            queryKey: 'sch_drive_occurrence_guest',
            forceRefresh: true,
        }));
        if (!Array.isArray(driveDetailResponse) || driveDetailResponse.length === 0) {
            throw new Error('An error occurred while fetching Drive details: No data found.');
        }
        const firstDriveOccurrence = first(driveDetailResponse);
        if (!firstDriveOccurrence) {
            throw new Error('The first drive occurrence has undefined properties.');
        }
        yield put(driveDetailsSuccess(firstDriveOccurrence));
        if (callback === null || callback === void 0 ? void 0 : callback.onSuccess) {
            yield call(callback.onSuccess, driveDetailResponse);
        }
    }
    catch (error) {
        yield call(catchError, { title: 'Fetch Drive Details Worker', error: error, skipToast: true });
        if (callback === null || callback === void 0 ? void 0 : callback.onError) {
            yield call(callback.onError, error);
        }
    }
}
export function* confirmDriveSlotWorker(action) {
    var _a;
    const { drive_id, from, to, callback } = action.payload;
    try {
        yield put(confirmDriveSlotRequest());
        const response = (yield call(postData, {
            queryString: CONFIRM_DRIVE_MEETING_SLOT,
            payload: { drive_id, from, to },
            spreadPayload: true,
        }));
        const confirmDriveResponse = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.sch_confirm_drive_slot;
        if (confirmDriveResponse.success) {
            yield put(confirmDriveSlotSuccess());
            if (callback === null || callback === void 0 ? void 0 : callback.onSuccess) {
                yield call(callback.onSuccess, confirmDriveResponse);
            }
        }
        else {
            throw new Error(confirmDriveResponse.error_message || 'Failed to confirm drive slot.');
        }
    }
    catch (error) {
        yield call(catchError, { title: 'Confirm Drive Slot Worker', error: error, skipToast: true });
        if (callback === null || callback === void 0 ? void 0 : callback.onError) {
            yield call(callback.onError, error);
        }
    }
}
export function* fetchDriveOccurrenceSagaWatcher() {
    yield takeLatest(fetchDriveDetails.type, fetchDriveOccurrenceGuestWorker);
}
export function* confirmDriveSlotSagaWatcher() {
    yield takeLatest(confirmDriveSlotRequest.type, confirmDriveSlotWorker);
}
export function* driveRootSaga() {
    yield fork(fetchDriveOccurrenceSagaWatcher);
    yield fork(confirmDriveSlotSagaWatcher);
}
