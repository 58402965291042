import { put, call, takeLatest, fork, select } from 'redux-saga/effects';
import { catchError } from '@utils/sentry';
import { fetchMeeting, storeMeeting } from './slice';
import { GET_MEETING } from './queries';
import fetchData from '@utils/fetchData';
import { selectMeeting } from './selectors';
import { selectUser } from '@containers/Auth/selectors';
import { findParticipant, getMeetingStates, meetingType } from './helpers';
export function* fetchMeetingWorker({ payload }) {
    var _a, _b;
    try {
        const user = (yield select(selectUser));
        const cachedMeeting = (yield select(selectMeeting));
        const { meetingId, participantId } = payload;
        const id = meetingId || (cachedMeeting === null || cachedMeeting === void 0 ? void 0 : cachedMeeting.id);
        const queryVariables = participantId
            ? { meeting_participants: { id: { _eq: participantId } } }
            : { id: { _eq: id } };
        const queryResponse = (yield call(fetchData, {
            queryString: GET_MEETING,
            queryVariables: { filter: queryVariables },
            queryKey: 'sch_meeting',
            forceRefresh: true,
            context: {},
        }));
        const meeting = queryResponse === null || queryResponse === void 0 ? void 0 : queryResponse[0];
        const userId = Number(user === null || user === void 0 ? void 0 : user.user_id);
        const participant = (yield call(findParticipant, meeting, userId, participantId));
        if (meeting && participant) {
            const meetingStates = (yield call(getMeetingStates, meeting, participant));
            const meetingConditionType = (yield call(meetingType, meeting, userId, participantId, participant));
            yield put(storeMeeting(Object.assign({ meeting,
                participant,
                meetingConditionType }, meetingStates)));
            if ((_a = payload.callback) === null || _a === void 0 ? void 0 : _a.onSuccess) {
                yield call(payload.callback.onSuccess, meeting);
            }
        }
        else {
            throw new Error('An error occurred while fetching the meetings data');
        }
    }
    catch (error) {
        yield call(catchError, { title: 'Meeting Worker', error: error });
        if ((_b = payload === null || payload === void 0 ? void 0 : payload.callback) === null || _b === void 0 ? void 0 : _b.onError) {
            yield call(payload.callback.onError, error);
        }
    }
}
export function* fetchMeetingWatcher() {
    yield takeLatest(fetchMeeting.type, fetchMeetingWorker);
}
export function* schedulingRootSaga() {
    yield fork(fetchMeetingWatcher);
}
