import { call, takeLatest, fork } from 'redux-saga/effects';
import { catchError } from '@utils/sentry';
import { acceptNewTime } from './slice';
import { CHANGE_INTERVIEWER } from './queries';
import postData from '@utils/postData';
export function* acceptTimebyInterviewer({ payload }) {
    var _a, _b;
    try {
        const queryVariables = payload === null || payload === void 0 ? void 0 : payload.data;
        yield call(postData, {
            queryString: CHANGE_INTERVIEWER,
            payload: queryVariables,
            spreadPayload: true,
        });
        if ((_a = payload.callback) === null || _a === void 0 ? void 0 : _a.onSuccess) {
            yield call(payload.callback.onSuccess);
        }
        else {
            throw new Error('An error occurred while accepting the proposed time');
        }
    }
    catch (error) {
        yield call(catchError, { title: 'Accept Time', error: error });
        if ((_b = payload.callback) === null || _b === void 0 ? void 0 : _b.onError) {
            yield call(payload.callback.onError, error);
        }
    }
}
export function* acceptTimebyInterviewerWatcher() {
    yield takeLatest(acceptNewTime.type, acceptTimebyInterviewer);
}
export function* acceptProposedTimeSaga() {
    yield fork(acceptTimebyInterviewerWatcher);
}
