import { get, upperCase } from 'lodash';
export const stepTypes = {
    INTERVIEW: 'INTERVIEW',
    LIVE: 'LIVE',
    ASSESSMENT: 'ASSESSMENT',
    SCREENING: 'SCREENING',
};
export const supportedStepTypes = [stepTypes.ASSESSMENT, stepTypes.SCREENING, stepTypes.INTERVIEW];
export const newSupportedStepTypes = [stepTypes.INTERVIEW, stepTypes.ASSESSMENT, stepTypes.SCREENING];
export const getFormattedStepType = (step) => {
    const stepType = typeof step === 'object' && step ? step.step_type : step;
    const type = stepType ? upperCase(stepType.trim()) : '';
    if (type === 'ALL') {
        return stepType;
    }
    return type && get(stepTypes, type);
};
export const isStepTypeAssessment = (step) => getFormattedStepType(step) === stepTypes.ASSESSMENT;
export const isStepTypeInterview = (step) => getFormattedStepType(step) === stepTypes.INTERVIEW;
export const isStepTypeScreening = (step) => getFormattedStepType(step) === stepTypes.SCREENING;
