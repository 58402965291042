import { gql } from '@apollo/client';
export const processQuery = (queryString) => {
    try {
        if (typeof queryString !== 'string') {
            return queryString;
        }
        return gql `
      ${queryString}
    `;
    }
    catch (error) {
        throw Error(`${error}:Invalid Query`);
    }
};
