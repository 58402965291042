import { createSelector } from '@reduxjs/toolkit';
import { schedulingSlice } from '@containers/Scheduling/slice';
export const selectScheduling = createSelector((state) => state, (state) => state[schedulingSlice.name]);
export const selectMeeting = createSelector(selectScheduling, (state) => { var _a; return (_a = state === null || state === void 0 ? void 0 : state.data) === null || _a === void 0 ? void 0 : _a.meeting; });
export const selectParticipant = createSelector(selectScheduling, (state) => { var _a; return (_a = state === null || state === void 0 ? void 0 : state.data) === null || _a === void 0 ? void 0 : _a.participant; });
export const selectMeetingState = createSelector(selectScheduling, (state) => state === null || state === void 0 ? void 0 : state.meetingState);
export const selectHasMeetingStarted = createSelector(selectMeetingState, (state) => state === null || state === void 0 ? void 0 : state.isMeetingStarted);
export const selectIsMeetingCancelled = createSelector(selectMeetingState, (state) => state === null || state === void 0 ? void 0 : state.isMeetingCancelled);
export const selectIsMeetingCompleted = createSelector(selectMeetingState, (state) => state === null || state === void 0 ? void 0 : state.isMeetingCompleted);
export const selectIsMeetingDeclined = createSelector(selectMeetingState, (state) => state === null || state === void 0 ? void 0 : state.isMeetingDeclined);
export const selectIsCurrentUserParticipant = createSelector(selectMeetingState, (state) => state.isCurrentUserParticipant);
export const selectMeetingConditionType = createSelector(selectMeetingState, (state) => state === null || state === void 0 ? void 0 : state.meetingConditionType);
