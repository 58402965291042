import { put, call, takeLatest, fork, select } from 'redux-saga/effects';
import { selectQueryParams } from '@containers/Auth/selectors';
import { catchError } from '@utils/sentry';
import postData from '@utils/postData';
import { REGISTER_CANDIDATE_TO_DRIVE, UPDATE_CANDIDATE_DETAILS_MUTATION } from './queries';
import { registerCandidateToDriveRequest, registerCandidateToDriveSuccess, updateCandidateRequest, updateCandidateSuccess, } from './slice';
import { first, get } from 'lodash';
import { localStorageSetItem, PAYMENT_DETAILS } from '@utils/localStorageHelpers';
export function* updateCandidateSagaWorker({ payload }) {
    var _a, _b, _c;
    try {
        const { data } = payload;
        const queryResponse = (yield call(postData, {
            queryString: UPDATE_CANDIDATE_DETAILS_MUTATION,
            payload: data,
        }));
        const candidateData = (_b = (_a = queryResponse === null || queryResponse === void 0 ? void 0 : queryResponse.data) === null || _a === void 0 ? void 0 : _a.can_update_candidate) === null || _b === void 0 ? void 0 : _b[0];
        if (candidateData.success) {
            yield put(updateCandidateSuccess({ data: candidateData.data }));
            yield call(registerCandidateToDriveWorker, { payload: { callback: payload === null || payload === void 0 ? void 0 : payload.callback } });
        }
        else {
            throw new Error(candidateData.error_message || 'An error occurred while updating candidate details.');
        }
    }
    catch (error) {
        yield call(catchError, { title: 'Update candidate worker', error: error });
        if ((_c = payload === null || payload === void 0 ? void 0 : payload.callback) === null || _c === void 0 ? void 0 : _c.onError) {
            yield call(payload === null || payload === void 0 ? void 0 : payload.callback.onError, error);
        }
    }
}
export function* registerCandidateToDriveWorker({ payload }) {
    var _a, _b, _c, _d, _e;
    try {
        const queryParams = (yield select(selectQueryParams));
        const queryResponse = (yield call(postData, {
            queryString: REGISTER_CANDIDATE_TO_DRIVE,
            payload: {
                drive_schedule_id: queryParams.driveScheduleId && Number(queryParams.driveScheduleId),
            },
        }));
        const registerGuestResponse = (_a = queryResponse === null || queryResponse === void 0 ? void 0 : queryResponse.data) === null || _a === void 0 ? void 0 : _a.sch_register_guest;
        if (registerGuestResponse === null || registerGuestResponse === void 0 ? void 0 : registerGuestResponse.success_count) {
            const schRegisterGuest = first(registerGuestResponse.data);
            const orderItem = first((_c = (_b = schRegisterGuest === null || schRegisterGuest === void 0 ? void 0 : schRegisterGuest.drive_occurrence_guest) === null || _b === void 0 ? void 0 : _b.pay_item) === null || _c === void 0 ? void 0 : _c.order_items);
            const orderId = get(orderItem, 'order_id');
            yield call(localStorageSetItem, PAYMENT_DETAILS, String(orderId));
            yield put(registerCandidateToDriveSuccess({ orderId: Number(orderId) }));
            if ((_d = payload === null || payload === void 0 ? void 0 : payload.callback) === null || _d === void 0 ? void 0 : _d.onSuccess) {
                yield call(payload.callback.onSuccess, { orderId });
            }
        }
        else {
            throw new Error(JSON.stringify(registerGuestResponse) || 'An error occurred while registering the candidate to drive.');
        }
    }
    catch (error) {
        yield call(catchError, { title: 'registerCandidateToDriveWorker', error: error, skipToast: true });
        if ((_e = payload === null || payload === void 0 ? void 0 : payload.callback) === null || _e === void 0 ? void 0 : _e.onError) {
            yield call(payload === null || payload === void 0 ? void 0 : payload.callback.onError, error);
        }
    }
}
export function* updateCandidateWatcher() {
    yield takeLatest(updateCandidateRequest.type, updateCandidateSagaWorker);
}
export function* registerCandidateToDriveWatcher() {
    yield takeLatest(registerCandidateToDriveRequest, registerCandidateToDriveWorker);
}
export function* updateCandidateRootSaga() {
    yield fork(updateCandidateWatcher);
    yield fork(registerCandidateToDriveWatcher);
}
