import { createSlice } from '@reduxjs/toolkit';
import { getAllParams } from '@containers/Auth/helpers';
export const initialState = {
    initializing: false,
    initialized: false,
    authenticated: false,
    authenticating: false,
    idToken: null,
    accessToken: null,
    expiryTime: null,
    verificationCodeRequested: false,
    user: {},
    queryParams: {},
    verificationId: '',
    currentUser: null,
    candidateDetails: null,
};
export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        initialize: () => {
            return Object.assign(Object.assign({}, initialState), { initializing: true, queryParams: getAllParams() });
        },
        initializeComplete: (state) => {
            state.initializing = false;
            state.initialized = true;
        },
        authenticate: (state, action) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
            state.initializing = false;
            state.initialized = true;
            state.authenticated = !!(((_a = action.payload) === null || _a === void 0 ? void 0 : _a.accessToken) || '').trim();
            state.authenticating = false;
            state.accessToken = ((_b = action.payload) === null || _b === void 0 ? void 0 : _b.accessToken) || null;
            state.idToken = ((_c = action.payload) === null || _c === void 0 ? void 0 : _c.idToken) || null;
            state.customToken = ((_d = action.payload) === null || _d === void 0 ? void 0 : _d.customToken) || null;
            state.hash_token = ((_e = action.payload) === null || _e === void 0 ? void 0 : _e.hash_token) || null;
            state.expiryTime = ((_g = (_f = action.payload) === null || _f === void 0 ? void 0 : _f.parsedToken) === null || _g === void 0 ? void 0 : _g.expiryTime) || null;
            state.user = Object.assign(Object.assign({}, (_h = action.payload) === null || _h === void 0 ? void 0 : _h.candidate), { phone_number: ((_k = (_j = action.payload) === null || _j === void 0 ? void 0 : _j.candidate) === null || _k === void 0 ? void 0 : _k.phone_number) || state.user.phone, email: ((_m = (_l = action.payload) === null || _l === void 0 ? void 0 : _l.candidate) === null || _m === void 0 ? void 0 : _m.email) || state.user.username, username: ((_p = (_o = action.payload) === null || _o === void 0 ? void 0 : _o.candidate) === null || _p === void 0 ? void 0 : _p.email) || state.user.username, user_id: ((_r = (_q = action.payload) === null || _q === void 0 ? void 0 : _q.parsedToken) === null || _r === void 0 ? void 0 : _r.user_id) || null, tenant_id: ((_t = (_s = action.payload) === null || _s === void 0 ? void 0 : _s.parsedToken) === null || _t === void 0 ? void 0 : _t.tenant_id) || null, roles: ((_v = (_u = action.payload) === null || _u === void 0 ? void 0 : _u.parsedToken) === null || _v === void 0 ? void 0 : _v.roles) || null });
        },
        authenticateFailed: (state) => {
            state.initializing = false;
            state.initialized = true;
            state.authenticated = false;
            state.authenticating = false;
        },
        unAuthenticate: (state) => {
            state.initializing = false;
            state.initialized = true;
            state.authenticated = false;
            state.authenticating = false;
        },
        getEmailOtp: (_state, _action) => {
            // state.user.username = action.payload.username;
        },
        getPhoneFirebaseOtp: (_state, _action) => {
            // state.user.phone = action.payload.phone;
        },
        getEmailOtpSuccess: (state, action) => {
            state.user.username = action.payload.username;
            state.verificationCodeRequested = true;
        },
        getPhoneFirebaseOtpSuccess: (state, action) => {
            var _a;
            state.verificationId = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.verificationId;
            state.user.phone = action.payload.phone;
            state.verificationCodeRequested = true;
        },
        verifyEmailOtp: (_state, _action) => { },
        verifyFirebaseOtp: (_state, _action) => { },
        storeIdToken: (state, action) => {
            state.idToken = action.payload;
            state.authenticating = true;
        },
        storeQueryParams: (state, action) => {
            state.queryParams = action.payload;
        },
        initializeSocialLogin: (_state, _action) => { },
        storeCurrentUser: (state, action) => {
            state.currentUser = action.payload;
        },
        storeStoredSession: (state, action) => {
            var _a, _b, _c;
            state.accessToken = (_a = action.payload.accessToken) !== null && _a !== void 0 ? _a : null;
            state.idToken = (_b = action.payload.idToken) !== null && _b !== void 0 ? _b : null;
            state.customToken = action.payload.customToken;
            state.hash_token = action.payload.hash_token;
            state.tenant_id = (_c = action.payload.tenant_id) !== null && _c !== void 0 ? _c : null;
        },
        fetchCandidateDetails: (_state, _action) => { },
        storeCandidateDetails: (state, action) => {
            state.candidateDetails = action.payload;
        },
    },
});
export const { initialize, initializeComplete, authenticate, unAuthenticate, getEmailOtp, getPhoneFirebaseOtp, getEmailOtpSuccess, verifyEmailOtp, verifyFirebaseOtp, getPhoneFirebaseOtpSuccess, authenticateFailed, storeIdToken, storeQueryParams, initializeSocialLogin, storeCurrentUser, storeStoredSession, fetchCandidateDetails, storeCandidateDetails, } = authSlice.actions;
export default authSlice.reducer;
