import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage, useIntl } from 'react-intl';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { Button } from '@components/base/Button';
import { getAssetUrl } from '@utils/getAssetUrl';
import messages from './messages';
function LazyLoaderErrorBoundary() {
    const error = useRouteError();
    const intl = useIntl();
    const handleContactSupport = () => {
        window.location.reload();
    };
    const renderErrorMessage = () => {
        if (isRouteErrorResponse(error)) {
            return `${error === null || error === void 0 ? void 0 : error.status} ${error === null || error === void 0 ? void 0 : error.statusText}`;
        }
        if (typeof error === 'object' && error !== null && 'message' in error) {
            return error === null || error === void 0 ? void 0 : error.message;
        }
        return String(error);
    };
    return (_jsxs("div", { className: "flex flex-col flex-1 justify-center items-center text-center lg:px-32 lg:py-4", "data-testid": "error-boundary", children: [_jsx("div", { className: "text-gray-900 md:font-bold lg:text-3xl md:text-xl text-lg font-semibold mb-4 mt-2", "data-testid": "error-heading", children: _jsx(FormattedMessage, Object.assign({}, messages === null || messages === void 0 ? void 0 : messages.heading)) }), _jsx("div", { className: "mb-4", "data-testid": "error-image-container", children: _jsx("img", { src: getAssetUrl('connection-lost.png'), className: "h-60 md:h-72 lg:h-80", alt: intl.formatMessage(messages === null || messages === void 0 ? void 0 : messages.errorImage), "data-testid": "error-image" }) }), _jsxs("div", { className: "px-4 mb-4 font-normal lg:text-lg md:text-base text-sm", "data-testid": "error-message", children: [renderErrorMessage(), _jsx("br", {}), _jsx(FormattedMessage, Object.assign({}, messages === null || messages === void 0 ? void 0 : messages.description))] }), _jsx("div", { children: _jsx(Button, { variant: "secondary", className: "rounded", onClick: handleContactSupport, "data-testid": "contact-support-button", children: _jsx(FormattedMessage, Object.assign({}, messages === null || messages === void 0 ? void 0 : messages.retry)) }) })] }));
}
export default LazyLoaderErrorBoundary;
