import Mixpanel from 'mixpanel-browser';
import { mixpanelEvents } from './mixpanelEvent';
import { appName } from '@utils/localStorageHelpers';
import store from '@store/index';
export const trackEvent = ({ event, options, preAuth }) => {
    var _a;
    const state = store.getState();
    const user = (_a = state === null || state === void 0 ? void 0 : state.auth) === null || _a === void 0 ? void 0 : _a.user;
    if (!(user === null || user === void 0 ? void 0 : user.tenant_id) && !preAuth)
        return;
    const defaultOptions = Object.assign({ Application: appName, 'Application Version': process.env.version, 'Application Environment': process.env.APP_ENV, 'User Id': (user === null || user === void 0 ? void 0 : user.user_id) ? Number(user === null || user === void 0 ? void 0 : user.user_id) : undefined, 'Organization Id': (user === null || user === void 0 ? void 0 : user.tenant_id) ? Number(user === null || user === void 0 ? void 0 : user.tenant_id) : undefined }, options);
    Mixpanel.track(event, Object.assign({}, defaultOptions));
};
export const socialLoginInitiatedEvent = (options) => {
    trackEvent({
        event: mixpanelEvents.CANDIDATE_LOGIN_SOCIAL_MEDIA_INITIATED,
        options: options,
        preAuth: true,
    });
};
export const socialLoginSuccessEvent = (options) => {
    trackEvent({
        event: mixpanelEvents.CANDIDATE_LOGIN_SOCIAL_MEDIA_SUCCESS,
        options: options,
    });
};
export const instructionPageLandedEvent = (options) => {
    trackEvent({
        event: mixpanelEvents.CANDIDATE_INSTRUCTION_PAGE_LANDED,
        options: options,
    });
};
export const bulkAnswerSubmitted = (options) => {
    trackEvent({
        event: mixpanelEvents.CANDIDATE_ASSESSMENT_SUBMITTED,
        options: options,
    });
};
export const candidateAnswerSelected = (options) => {
    trackEvent({
        event: mixpanelEvents.CANDIDATE_ANSWER_SELECTED,
        options: options,
    });
};
export const candidateNextQuestionClicked = (options) => {
    trackEvent({
        event: mixpanelEvents.CANDIDATE_NEXT_QUESTION_CLICKED,
        options: options,
    });
};
export const candidateLoginOtpRequested = (options) => {
    trackEvent({
        event: mixpanelEvents.CANDIDATE_LOGIN_OTP_REQUESTED,
        options: options,
        preAuth: true,
    });
};
export const candidateLoginSocialMediaLoginFailed = (options) => {
    trackEvent({
        event: mixpanelEvents.CANDIDATE_LOGIN_SOCIAL_MEDIA_LOGIN_FAILED,
        options: options,
        preAuth: true,
    });
};
export const candidateLoginOtpFilledLoginSuccess = (options) => {
    trackEvent({
        event: mixpanelEvents.CANDIDATE_LOGIN_OTP_FILLED_LOGIN_SUCCESS,
        options: options,
    });
};
export const candidateScorePageNextClicked = (options) => {
    trackEvent({
        event: mixpanelEvents.CANDIDATE_SCORE_PAGE_NEXT_CLICKED,
        options: options,
    });
};
export const taskTakerFeedbackPage = (options) => {
    trackEvent({
        event: mixpanelEvents.TASK_TAKER_FEEDBACK_PAGE,
        options: options,
    });
};
export const candidateScorePageLoad = (options) => {
    trackEvent({
        event: mixpanelEvents.CANDIDATE_SCORE_PAGE_LOAD,
        options: options,
    });
};
export const candidateAuthenticated = () => {
    trackEvent({
        event: mixpanelEvents.CANDIDATE_AUTHENTICATED,
        options: {},
    });
};
export const stopRecordingClick = () => {
    trackEvent({
        event: mixpanelEvents.STOP_RECORDING_CLICK,
        options: {},
    });
};
export const nextAsyncQuestionClick = () => {
    trackEvent({
        event: mixpanelEvents.NEXT_ASYNC_QUESTION_CLICK,
        options: {},
    });
};
export const candidateAsyncPermissionAllowed = (options) => {
    trackEvent({
        event: mixpanelEvents.CANDIDATE_ASYNC_PERMISSION_ALLOWED,
        options,
    });
};
export const candidateAsyncTogglePreviewClick = (hide) => {
    trackEvent({
        event: mixpanelEvents.CANDIDATE_ASYNC_TOGGLE_PREVIEW_CLICK,
        options: { 'Preview Status': hide ? 'hidden' : 'visible' },
    });
};
export const candidatePaymentProceed = (options) => {
    trackEvent({
        event: mixpanelEvents.CANDIDATE_PAYMENT_PROCEED,
        options,
    });
};
export const candidateCouponCodeApply = (options) => {
    trackEvent({
        event: mixpanelEvents.CANDIDATE_COUPON_CODE_APPLY,
        options,
    });
};
export const candidateScheduleClick = (options) => {
    trackEvent({
        event: mixpanelEvents.CANDIDATE_SCHEDULE_CLICK,
        options,
    });
};
export const candidateLoginClick = (options) => {
    trackEvent({
        event: mixpanelEvents.CANDIDATE_LOGIN_CLICK,
        options,
    });
};
export const candidateSlotBookingConfirm = (options) => {
    trackEvent({
        event: mixpanelEvents.CANDIDATE_SLOT_BOOKING_CONFIRM,
        options,
    });
};
export const candidateRegistrationContinue = (options) => {
    trackEvent({
        event: mixpanelEvents.CANDIDATE_REGISTRATION_CONTINUE,
        options,
    });
};
