import { gql } from '@apollo/client';
export const GET_MEETING_SLOTS = gql(`
query getMeetingSlots($meeting_id: Int!) {
  sch_get_meeting_slots(payload: [{meeting_id: $meeting_id}]) {
    data {
      meeting_id
      slots {
        from_date
        schedule_id
        sort_key
        to_date
        user_id
        weekday
      }
      duration
      from
      to
    }
    error_message
    success
  }
}
`);
