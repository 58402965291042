import { createSlice } from '@reduxjs/toolkit';
export const initialState = {};
export const AcceptProposedTimeSlice = createSlice({
    name: 'accept-propsed-time-scheduling',
    initialState,
    reducers: {
        acceptNewTime(_state, _action) { },
    },
});
export const { acceptNewTime } = AcceptProposedTimeSlice.actions;
export default AcceptProposedTimeSlice.reducer;
