var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import fetchData from '@utils/fetchData';
import { call, fork, takeEvery, take, delay, cancel } from '@redux-saga/core/effects';
import { catchError } from '@utils/sentry';
import { fetchFileUrl, triggerHeardBeat, stopHeartBeat, } from '@containers/App/types';
import { FETCH_FILE_URL, HEART_BEAT } from '@containers/App/queries';
export function* fetchFileUrlSaga({ payload: { data, callback }, }) {
    const queryVariables = {
        id: Number(data.id),
    };
    try {
        const response = (yield call(fetchData, {
            queryString: FETCH_FILE_URL,
            queryVariables,
        }));
        const data = response === null || response === void 0 ? void 0 : response.file_download_file;
        if (data && (data === null || data === void 0 ? void 0 : data.resource_url) && (callback === null || callback === void 0 ? void 0 : callback.onSuccess)) {
            yield call(callback === null || callback === void 0 ? void 0 : callback.onSuccess, data);
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'fetchFileUrlSaga',
            extraScope: { key: 'file_id', value: String(data.id) }, // Example usage of extraScope
            error: error,
        });
        if (callback && (callback === null || callback === void 0 ? void 0 : callback.onError)) {
            yield call(callback === null || callback === void 0 ? void 0 : callback.onError, error);
        }
    }
}
let secondsElapsed = 0;
let timer = null;
const startSecondsElapseTimer = (start = false) => {
    if (start && !timer) {
        timer = setInterval(() => {
            secondsElapsed = secondsElapsed + 1;
        }, 1000);
    }
    else if (!start) {
        secondsElapsed = 0;
        if (timer) {
            clearInterval(timer);
        }
        timer = null;
    }
};
export const makeHeartBeatApiCall = (payload) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        yield fetchData({
            queryString: HEART_BEAT,
            queryVariables: Object.assign(Object.assign({}, payload), { seconds_elapsed: secondsElapsed }),
            forceRefresh: true,
        });
        startSecondsElapseTimer(false);
    }
    catch (error) {
        startSecondsElapseTimer(true);
        catchError({
            title: 'makeHeartBeatApiCall',
            error: error,
            skipToast: true,
            extraScope: { key: 'payload', value: JSON.stringify(payload) },
        });
        return Promise.resolve();
    }
});
export function* heartBeatWorker({ payload }) {
    try {
        while (true) {
            yield call(makeHeartBeatApiCall, payload);
            yield delay(10000);
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'heartBeatWorker',
            error: error,
            skipToast: true,
            extraScope: { key: 'payload', value: JSON.stringify(payload) },
        });
        yield delay(2000);
    }
}
export function* heartBeatWatcher() {
    while (true) {
        const { payload } = yield take(triggerHeardBeat);
        const task = (yield fork(heartBeatWorker, { payload }));
        const stopAction = (yield take(stopHeartBeat));
        if (stopAction) {
            yield cancel(task);
        }
    }
}
export function* fetchFileUrlWatcher() {
    yield takeEvery(fetchFileUrl, fetchFileUrlSaga);
}
export function* appRootSaga() {
    yield fork(fetchFileUrlWatcher);
    yield fork(heartBeatWatcher);
}
