import { createSlice } from '@reduxjs/toolkit';
export const initialState = {
    slots: [],
    loading: true,
    error: null,
};
export const meetingSlotsSlice = createSlice({
    name: 'meetingSlots',
    initialState,
    reducers: {
        fetchMeetingSlotsRequest(state, _action) {
            state.loading = true;
        },
        fetchMeetingSlotsSuccess(state, action) {
            state.loading = false;
            state.slots = action.payload;
        },
        // fetchMeetingSlotsFailure(state, action: PayloadAction<string>) {
        //   state.loading = false;
        //   state.error = action.payload;
        // },
    },
});
export const { fetchMeetingSlotsRequest, fetchMeetingSlotsSuccess } = meetingSlotsSlice.actions;
export default meetingSlotsSlice.reducer;
