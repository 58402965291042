import { createSlice } from '@reduxjs/toolkit';
export const initialState = {};
export const ProposeNewTimeSlice = createSlice({
    name: 'propose-newtime-scheduling',
    initialState,
    reducers: {
        proposeNewTime(_state, _action) { },
        fetchBusySlots(_state, _action) { },
        storeBusySlots(_state, _action) { },
    },
});
export const { storeBusySlots, proposeNewTime, fetchBusySlots } = ProposeNewTimeSlice.actions;
export default ProposeNewTimeSlice.reducer;
